/* You can add global styles to this file, and also import other style files */
:root {
    // Dimensions
    --headerHeight: 35px;
    --defaultMargin: 20px;
    --headerAdjustedHeight: calc(var(--headerHeight) + var(--defaultMargin)); // 35px + 60px = 95px;

    // Colors
    --black: #333;
    --white: #fff;
    --gray-dark: #4d4d4d;
    --gray-medium: #ccc;
    --gray-light: #f3f3f3;
    --gray-shadow: #ddd;

    --yellow: #fce500;
    --blue: #27509b;

    --bfg-red: #bc403b;
    --bfg-blue: #123262;

    --bfg-tradesmen-blue: #122a72;
    --bfg-tradesmen-red: #f9140c;

    --uniroyal-grey: #353636;
    --uniroyal-red: #ea3a33;
    --uniroyal-logo-black: #231f20;

    --tireWallDefaultMargin: 20px;
    --globalBorderRadius: 10px;
    --globalBoxShadow: 3px 3px 5px 1px var(--gray-medium);
}

// Global Reset
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
}

body {
    font-family: "Open Sans", sans-serif;
    background-color: var(--gray-light);
    height: 100vh;
}

.semibold {
    font-weight: 600;
}

a {
    text-decoration: none;
}

.c-page {
    overflow-y: auto;
    padding: calc(2 * var(--tireWallDefaultMargin)) var(--tireWallDefaultMargin);
    z-index: 10;
    position: relative;

    .buttons {
        display: flex;

        & > * {
            margin-right: calc(var(--tireWallDefaultMargin) / 2);
        }
    }
}

// Handle dynamic content
.product-description {
    font-size: 13px;
    line-height: 1.4;
    color: var(--black);

    ul {
        list-style: outside;
        padding-left: 1rem;
        max-width: 95%;
    }

    & > * {
        margin-bottom: calc(var(--tireWallDefaultMargin) / 4);
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

.flex-row-break {
    flex-basis: 100%;
    height: 0;
}

// Overrideing Tire Pros common component styles
.background--top {
    z-index: 2 !important;
}

.header {
    background-color: var(--white);
    z-index: 1000;

    .header__time {
        margin-right: 150px;
    }
}
